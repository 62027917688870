<!-- eslint-disable no-console -->
<script>
import Swal from "sweetalert2";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import api from "../../../../common/axios";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Admin",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        api: "user",
      },
      tableData: [],
      title: "Admin",
      items: [
        {
          text: "Admin",
          href: "/admin",
        },
        {
          text: "View",
          href: "/admin",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "full_name", label: "Nama", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "role.name", label: "Role" },
        { key: "status", label: "Status", sortable: true },
        { key: "action" },
      ],
      routeBase: "admin",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.$toast(_.$route.params.title, {
          icon: _.$route.params.icon,
          type: _.$route.params.event,
        });
      }
    }
    // Set the initial number of items
    // this.totalRows = this.items.length;
  },
  methods: {
    getItems(ctx, callback) {
      const _ = this;
      let filter = ctx.filter ? "&key=" + ctx.filter : "";
      _.sortBy = ctx.sortBy !== null ? _.sortBy : ctx.sortBy;
      let sort = ctx.sortDesc == true ? "asc" : "desc";
      api
        .get(
          _.config.api +
            "?per_page=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            "&orderby=" +
            _.sortBy +
            "&sort=" +
            sort +
            filter
        )
        .then((resp) => {
          const consume = resp.data.data;
          _.perPage = consume.per_page;
          _.currentPage = consume.current_page;
          _.totalRows = consume.total_data;
          callback(consume.rows || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    gotoShowPage(id) {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/" + id + "/show");
    },

    goToAddPage() {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/add");
    },

    goToEditPage(id) {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/" + id + "/edit");
    },

    deleteData(id) {
      let _ = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          api
            .delete(_.config.api + "/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Berhasil Dihapus",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableFe.refresh();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "Tidak bisa di hapus",
                text: err.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    changePerPage() {
      const _ = this;
      _.$refs.tableFe.refresh();
    },

    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_length"
                  class="dataTables_length"
                  v-if="can('Admin store')"
                >
                  <b-button
                    @click="goToAddPage"
                    variant="primary"
                    class="pl-3 pr-3"
                    >{{ $t("listTable.addButton") }}</b-button
                  >
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div class="d-flex justify-content-end">
                  <div class="mr-2">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      :placeholder="$t('listTable.search')"
                      class="form-control"
                    ></b-form-input>
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                show-empty
                :items="getItems"
                :fields="fields"
                striped
                hover
                responsive="sm"
                :perPage="perPage"
                :current-page="currentPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @changePerPage="changePerPage"
                ref="tableFe"
              >
                <template #cell(status)="data">
                  <span class="text-capitalize">{{ data.item.status }}</span>
                </template>
                <template #cell(action)="data">
                  <div style="min-width: 80px">
                    <b-button
                      v-if="can('Admin update')"
                      v-b-tooltip.hover.top="'Edit'"
                      variant="warning"
                      size="sm"
                      class="mr-1"
                      @click="goToEditPage(data.item.id)"
                    >
                      <i class="ti-pencil"></i>
                    </b-button>

                    <b-button
                      v-b-tooltip.hover.top="'Hapus'"
                      variant="light"
                      size="sm"
                      v-if="data.item.id != 1 && can('Admin delete')"
                      @click="deleteData(data.item.id)"
                    >
                      <i class="ti-trash"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t("listTable.show") }}
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      class="ml-1 mr-1"
                      :options="pageOptions"
                    ></b-form-select
                    >{{ $t("listTable.entries") }}
                  </label>
                </div>
              </div>
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
